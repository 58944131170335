import { apiMainQuery } from '../../settings/apollo/api/main/query';
import { apiMainMutation } from '../../settings/apollo/api/main/mutation';
import {
    systemIndustriesList,
    saveBusinessInfoMutation,
    updateBusinessInfoMutation,
    customerBusinessInfoQuery,
    saveBusinessDirectorOwnerMutation,
    saveBusinessDirectorOwnerInfoMutation,
    updateBusinessDirectorOwnerInfoMutation,
    businessDirectorOwnerInfoQuery,
    uploadBusinessFileMutation,
    uploadFileMutation,
    updateCustomerPersonalInfoMutation,
    cusOnlineComplianceQuery,
    businessOnlineComplianceQuery,
    sendBusinessInviteMutation,
    registerBusinessAccountMutation,
    switchAccountTypeBusiness,
    customerBusinessInfoQueryV2
} from './graphql.graphql';
import {
    SET_BUSINESS_ACC,
    SET_PERSONAL_ACC,
    SET_BUSINESS_COMPLIANCE,
    TOGGLE_BUSINESS_VERIFICATION,
    SET_EDIT_ID,
    SET_IS_MSB,
    SET_BUSINESS_ID,
    SET_PROMO_STATUS,
    SET_OWNER_DIRECTOR,
    SET_CURRENT_FLOW,
    SET_BUSINESS_UNIQUE_ID,
    SET_BUSINESS_INFO,
    SET_BUSINESS_REDIRECT_ROUTE,
    SET_OWNER_DIRECTOR_INFO,
    SET_OWNER_DIRECTOR_FORM_DATA,
    SET_OWNER_DIRECTOR_FORM_STATUS,
    SET_OWNERSHIP_INFO,
    SET_DIRECTORSHIP_INFO,
    SET_OWNER_LIST_TYPE,
    SET_DIRECTOR_LIST_TYPE,
    SET_OWNER_LIST,
    SET_DIRECTOR_LIST,
    SET_DIRECTOR_FORM_STATUS,
    SET_DIRECTOR_FORM_DATA,
    SET_DIRECTOR_UPLOAD_METHOD
} from './actionTypes';

export const systemIndustriesListQuery = () => apiMainQuery(systemIndustriesList);

export const verificationLevelQuery = () => apiMainQuery(cusOnlineComplianceQuery);

export const businessDataQuery = async (inDepth = false) => {
    const result = await apiMainQuery(customerBusinessInfoQuery, { inDepth });
    if (result && result.data && result.data.customerBusinessInfo) setBusinessUniqueId(result.data.customerBusinessInfo.business_unique_id);
    return result;
};

export const businessDataQueryV2 = async (inDepth = false) => {
    const result = await apiMainQuery(customerBusinessInfoQueryV2, { inDepth });
    if (result && result.data && result.data.customerBusinessInfo) setBusinessUniqueId(result.data.customerBusinessInfo.business_unique_id);
    return result;
};

export const ownerDirectorQuery = () => apiMainQuery(businessDirectorOwnerInfoQuery);

export const setAccountTypeBusiness = () => {
    return {
        type: SET_BUSINESS_ACC
    }
};
export const setAccountTypePersonal = () => {
    return {
        type: SET_PERSONAL_ACC
    }
};

export const updateCusAccRole = (role, isRegistrationForm) => {
    return apiMainMutation(updateCustomerPersonalInfoMutation, { personalInformation: { business_ac_role: role, is_registration_form: isRegistrationForm } })
}

export const saveBusinessInfo = (accountInformation) => {
    return apiMainMutation(saveBusinessInfoMutation, { accountInformation });
};

export const saveBusinessFile = (business_id, container, type, files) => {
    return apiMainMutation(uploadBusinessFileMutation, { business_id, container, type, files })
}

export const saveBusinessRegistrationFile = (business_id, container, files) => {
    return apiMainMutation(uploadBusinessFileMutation, { business_id, container, files })
}

export const saveDirectorIdFile = (container, file, type, businessDirectorId) => {
    return apiMainMutation(uploadFileMutation, { container, file, type, businessDirectorId })
}

export const savePersonalAddressFile = (container, file, type, businessDirectorId) => {
    return apiMainMutation(uploadFileMutation, { container, file, type, businessDirectorId })
}

export const savePersonalIdFile = (container, file, type, businessDirectorId) => {
    return apiMainMutation(uploadFileMutation, { container, file, type, businessDirectorId })
}

export const updateBusinessInfo = (business_id, accountInformation, businessAddress) => {
    if (accountInformation === null) {
        return apiMainMutation(updateBusinessInfoMutation, { business_id, businessAddress })
    }
    else if (businessAddress === null) {
        return apiMainMutation(updateBusinessInfoMutation, { business_id, accountInformation })
    }
    return apiMainMutation(updateBusinessInfoMutation, { business_id, accountInformation, businessAddress })
};

export const saveOwner = (business_id, accountInformation, accountAddress) => {
    return apiMainMutation(saveBusinessDirectorOwnerMutation, { business_id, accountInformation, accountAddress });
}

export const saveOwnerInfo = (business_id, accountInformation) => {
    return apiMainMutation(saveBusinessDirectorOwnerInfoMutation, { business_id, accountInformation });
}

export const saveOwnerAddress = (id, business_id, accountInformation, accountAddress) => {
    if (accountInformation === null) {
        return apiMainMutation(updateBusinessDirectorOwnerInfoMutation, { id, business_id, accountAddress })
    }
    else if (accountAddress === null) {
        return apiMainMutation(updateBusinessDirectorOwnerInfoMutation, { id, business_id, accountInformation })
    }
    return apiMainMutation(updateBusinessDirectorOwnerInfoMutation, { id, business_id, accountInformation, accountAddress })
}

export const setOwnerDirectorInfo = (info) => {
    return {
        type: SET_OWNER_DIRECTOR_INFO,
        payload: info
    }
}

export const setOwnerDirectorFormData = (info) => {
    return {
        type: SET_OWNER_DIRECTOR_FORM_DATA,
        payload: info
    }
}

export const setOwnerDirectorFormStatus = (info) => {
    return {
        type: SET_OWNER_DIRECTOR_FORM_STATUS,
        payload: info
    }
}

export const toggleBusinessVerification = () => {
    return {
        type: TOGGLE_BUSINESS_VERIFICATION
    }
};

export const setEditId = (id) => {
    return {
        type: SET_EDIT_ID,
        payload: id
    }
}

export const setOwnerDirectorId = ({ id, type }) => {
    return {
        type: SET_OWNER_DIRECTOR,
        payload: { id, type }
    }
}

export const setIsMsb = (value) => {
    return {
        type: SET_IS_MSB,
        payload: value
    }
}

export const setBusinessId = (id) => {
    return {
        type: SET_BUSINESS_ID,
        payload: id
    }
}

export const setPromoStatus = (id) => {
    return {
        type: SET_PROMO_STATUS,
        payload: id
    }
}

export const setCurrentFlow = (flow) => {
    return {
        type: SET_CURRENT_FLOW,
        payload: flow
    }
}

export const setBusinessUniqueId = (id) => dispatch =>
    dispatch({
        type: SET_BUSINESS_UNIQUE_ID,
        payload: id
    })

// export const setInstantSpeed = is_instant_speed => dispatch =>
//   dispatch({ type: SET_INSTANT_SPEED, payload: { is_instant_speed } });

export const updateCustomer = (personalInformation) => {
    return apiMainMutation(updateCustomerPersonalInfoMutation, { personalInformation })
}


export const businessComplianceQuery = (business_unique_id) => {
    return apiMainMutation(businessOnlineComplianceQuery, { business_unique_id })
}

export const fetchBusinessCompliance = async (business_unique_id) => {
    return async (dispatch) => {
        const { success, data } = await businessComplianceQuery(business_unique_id);
        if (success && data.businessOnlineCompliance) {
            const {
                business_level,
                max_level,
                daily_limit_available,
                monthly_limit_available,
                max_daily_limit_available,
                max_monthly_limit_available,
            } = data.businessOnlineCompliance;

            const customer_level = business_level;
            const pendingLevels = [];

            dispatch({
                type: SET_BUSINESS_COMPLIANCE,
                payload: {
                    customer_level,
                    max_level,
                    daily_limit_available,
                    monthly_limit_available,
                    max_daily_limit_available,
                    max_monthly_limit_available,
                    pendingLevels
                }
            })
        }
    };
};

export const setBusinessInfo = (payload) => {
    return {
        type: SET_BUSINESS_INFO,
        payload
    }
}

export const setBusinessRedirectRoute = (route) => dispatch =>
    dispatch({
        type: SET_BUSINESS_REDIRECT_ROUTE,
        payload: route
    })

export const sendBusinessInvite =
    ({ email_id, business_id }) =>
        async () => {
            const { success, data } = await apiMainMutation(sendBusinessInviteMutation, { email_id, business_id });

            return success && data.sendBusinessInvite;
        };

export const registerBusinessAccount = () => {
    return apiMainMutation(registerBusinessAccountMutation);
}

export const sendEmailInviteBusiness = (email_id, business_id) => {
    return apiMainMutation(sendBusinessInviteMutation, { email_id, business_id });
}


export const setOwnershipInfo = (payload) => {
    return {
        type: SET_OWNERSHIP_INFO,
        payload
    }
}

export const setDirectorshipInfo = (payload) => {
    return {
        type: SET_DIRECTORSHIP_INFO,
        payload
    }
}

export const setOwnerListType = (payload) => {
    return {
        type: SET_OWNER_LIST_TYPE,
        payload
    }
}

export const setDirectorListType = (payload) => {
    return {
        type: SET_DIRECTOR_LIST_TYPE,
        payload
    }
}

export const setOwnerList = (payload) => {
    return {
        type: SET_OWNER_LIST,
        payload
    }
}

export const setDirectorList = (payload) => {
    return {
        type: SET_DIRECTOR_LIST,
        payload
    }
}

export const setDirectorFormStatus = (payload) => {
    return {
        type: SET_DIRECTOR_FORM_STATUS,
        payload
    }
}

export const setDirectorFormData = (payload) => {
    return {
        type: SET_DIRECTOR_FORM_DATA,
        payload
    }
}

export const setDirectorUploadMethod = (payload) => {
    return {
        type: SET_DIRECTOR_UPLOAD_METHOD,
        payload
    }
}

export const swithAccountTypeBusiness = () => {
    return apiMainMutation(switchAccountTypeBusiness)
}

