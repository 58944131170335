import React from 'react';

const ArrowLeft = ({ width, height, color }) => {
    return (
        <svg
        width={width}
        height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="https://www.w3.org/2000/svg"
        >
            <path
                d="M19 12H5M5 12L12 19M5 12L12 5"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round" />
        </svg>
    );
};

export default ArrowLeft;
