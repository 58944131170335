import { useState, useEffect } from 'react';

const useCustomMediaQuery = (query) => {

  const isSSR = typeof window === 'undefined';

  const [matches, setMatches] = useState(!isSSR);

  useEffect(() => {
    if (!isSSR) {
      const mediaQueryList = window.matchMedia(query);

      setMatches(mediaQueryList.matches);

      const handleChange = (event) => {
        setMatches(event.matches);
      };

      mediaQueryList.addListener(handleChange);

      return () => {
        mediaQueryList.removeListener(handleChange);
      };
    }
  }, [query, isSSR]);

  return matches;
};

export default useCustomMediaQuery;
