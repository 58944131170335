import React from 'react';

const StepperCircle = ({ status }) => {
  let icon;

  if (status === 'active') {
    icon = (
      <svg
        xmlns="https://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <rect width="24" height="24" rx="12" fill="#C5D9FC" />
        <circle cx="12" cy="12" r="8" fill="#1160F7" />
        <circle cx="12" cy="12" r="4" fill="white" />
      </svg>
    );
  } else if (status === 'completed') {
    icon = (<svg xmlns="https://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <rect width="24" height="24" rx="12" fill="#C5D9FC" />
      <circle cx="12" cy="12" r="8" fill="#1160F7" />
      <path
        d="M9 12L11 14L15 10"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>);
  } else if (status === 'inactive') {
    icon = (
      <svg
        xmlns="https://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <rect width="24" height="24" rx="12" fill="#E8E8E8" />
        <circle cx="12" cy="12" r="8" fill="#C4C4C4" />
        <circle cx="12" cy="12" r="4" fill="white" />
      </svg>
    );
  } else if (status === 'warning') {
    icon = (
      <svg
        xmlns="https://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <rect width="24" height="24" rx="12" fill="#FEEEBF" />
        <circle cx="12" cy="12" r="8" fill="#FAC948" />
        <path
          d="M12 12V7.5M12 16H12.02"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else if (status === 'error') {
    icon = (
      <svg 
        xmlns="https://www.w3.org/2000/svg" 
        width="24" 
        height="24" 
        viewBox="0 0 24 24" 
        fill="none"
      >
        <rect width="24" height="24" rx="12" fill="#FFDCDA" />
        <circle cx="12" cy="12" r="8" fill="#AD1206" />
        <path
          d="M15 9L9 15M9 9L15 15" 
          stroke="white" 
          strokeWidth="2" 
          strokeLinecap="round" 
          strokeLinejoin="round"
        />
    </svg>);
  } else {
    icon = (
      <svg
        xmlns="https://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <rect width="24" height="24" rx="12" fill="#EBF2FE" />
        <circle cx="12" cy="12" r="8" fill="#C5D9FC" />
        <circle cx="12" cy="12" r="4" fill="white" />
      </svg>
    );
  }
  return icon;
};

export default StepperCircle;
