import React from 'react';

const ArrowLeft = ({ width, height, color }) => {
  return (
    <svg
      xmlns="https://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M15 17.25L9 11.25L15 5.25"
        stroke={color}
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowLeft;
