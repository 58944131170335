import React from 'react';
import classes from './TopBar.module.scss';
import CloseIcon from '../../icons/CloseIcon';
import ChevronLeft from '../../icons/ChevronLeft';
import StepperCircle from '../../icons/StepperCircle';
import LogoDesk from '../../../../public/remitbee_logo.png';
import LogoMob from '../../../../public/img/splash-logo.svg';
import Container from '../container/Container'
import Typography from '../typography/Typography';
import ArrowLeft from '../../icons/ArrowLeft';
import theme from '../../theme/Theme';
import Button from '../button/Button';
import AttachFileIconView from '../../icons/AttachFileIcon';
import { useMediaQuery } from '@mui/material';

interface Step {
    key: string,
    label: string | React.ReactNode,
    onClickHandler?: () => void,
}

type TopBarProps = {
    /**
     * @param {{ activeStep: string }} props - same as value in key of steps[]
     */
    activeStep?: string;

    /**
     * @param {{ steps: Array<object> }} props - array of objects with key & label
     */
    steps?: Step[];

    /**
     * @param {{ onBackAction: Function }} props - if back feature needed.
     */
    onBackAction?: () => void;

    /**
     * @param {{ onCloseAction: Function }} props - if close feature needed.
     */
    onCloseAction?: () => void;

    /**
     * @param {{ customStartAction: string | React.node  }} props - if custom startIcon needed other than logo/back.
     */
    customStartAction?: string | React.ReactNode;

    /**
     * @param {{ customEndAction: string | React.node  }} props - if custom endIcon needed other than close.
     */
    customEndAction?: string | React.ReactNode;

    /**
     * @param {{ showLogo: boolean  }} props - if logo needs, to be displayed.
     */
    showLogo?: boolean;

    /**
     * @param {{ showScreenNameInMobile: boolean  }} props - if true, displays current step name in mobile view.
     */
    showScreenNameInMobile?: boolean;

    /**
     * @param {{ showStepper: boolean  }} props - if true, displays stepper for desktop & progress bar for mobile.
     */
    showStepper?: boolean;
    onUpload?: () => void;
    fullWidthText?: boolean;
    stepperWidth?: any;
    lineClassName? : any;
}

const TopBar: React.FC<TopBarProps> = ({
    activeStep,
    steps,
    onBackAction,
    showLogo,
    onCloseAction,
    onUpload,
    customStartAction,
    customEndAction,
    showScreenNameInMobile,
    showStepper,
    stepperWidth,
    fullWidthText=false,
    lineClassName=null,
    ...rest
}) => {

    const currentStepIndex = steps?.findIndex(step => step.key === activeStep);
    const mobileProgress = showStepper && ((currentStepIndex + 0.5) / (steps.length - 1)) * 100;
    const isMobile =  useMediaQuery(`(max-width:${theme.breakpoints.lg}px)`)

    return (
        <>
            <div className={lineClassName ?classes[`rb-topBar-container-new`] :classes[`rb-topBar-container`]} {...rest}>
                <Container id="topBar" type={'large'} style={{ padding: '0 24px' }}>
                    <div className={classes[`rb-topBar-toolbar`]}>
                        <div className={classes[`rb-topBar-startAdornment`]}>
                            {(onBackAction && !showLogo) && (
                                <button onClick={onBackAction} id='btn-back'>
                                    <ChevronLeft width={26} height={26} color={'#1160F7'} />
                                    <Typography id='back' variant='body1' color={theme.palette.primary.blue}>Back</Typography>
                                </button>
                            )}
                            {showLogo && <>
                                <img src={LogoDesk} alt="Remitbee Logo" className={classes[`rb-topBar-deskLogo`]} />
                                <img src={LogoMob} alt="Remitbee Logo" className={classes[`rb-topBar-mobLogo`]} />
                            </>}
                            {customStartAction ? customStartAction : null}
                        </div>
                        <div className={classes[`rb-topBar-startAdornment-mobile`]}>
                            {(onBackAction && !showLogo) && (
                                <button onClick={onBackAction} id='btn-back-mobile'>
                                    <ArrowLeft width={24} height={24} color={theme.palette.primary.blue} />
                                </button>
                            )}
                        </div>
                        {showStepper && <div className={classes[`rb-stepper-container`]}>
                            {showScreenNameInMobile && <div className={classes[`rb-stepper-mobileHeader`]}>{steps[currentStepIndex].label}</div>}
                            <div className={`${classes[`rb-topBar-stepper`]} ${lineClassName}`}>
                                {(steps && steps.length) ? steps?.map((step, index) => {
                                    return (
                                        <div className={classes[`rb-topBar-step`]} key={index}>
                                            <div className={classes[`rb-stepper-step`]}>
                                                <div className={classes[`rb-stepper-icon`]} onClick={step.onClickHandler}>
                                                    <StepperCircle status={(step.key === activeStep) ? 'active' : (index < currentStepIndex) ? 'completed' : ''} />
                                                    <Typography variant='body3' align='center' className={classes[`rb-stepper-label`]}>{step.label}</Typography>
                                                </div>
                                               {/* { fullWidthText &&<Typography variant='body3' align='center' className={classes[`rb-stepper-label`]}><div style={{width:'73px'}}>
                                               {step.label}</div></Typography>} */}
                                            </div>
                                            {index < steps.length - 1 && <div style={stepperWidth && {width:  stepperWidth }} className={`${classes[`rb-stepper-connector`]} ${(index < currentStepIndex) ? classes[`rb-stepper-connector-active`] : ''}`} />}
                                        </div>
                                    )
                                }) : null}
                            </div>
                        </div>}
                        <div className={classes[`rb-topBar-endAdornment`]}>
                        {onCloseAction && <Button variant='text' id='close-icon' onClick={onCloseAction}><CloseIcon color={'#1160F7'} width={24} height={24}/></Button>}
                        {onUpload && <Button variant='text' id='upload-icon' onClick={onUpload}><AttachFileIconView color={'#1160F7'} width={24} height={24}/></Button>}
                        {customEndAction ? customEndAction : null}
                    </div>
                    </div>
                </Container>
            </div>

            {/* Mobile Version of Stepper */}
            {(showStepper) && <div className={(lineClassName  && !isMobile) ? classes['b-lineStepper-container'] :classes[`rb-mobileStepper-container`]}>
                <div className={(lineClassName && !isMobile)?classes[`rb-lineStepper-progress`] :classes[`rb-mobileStepper-progress`]}>
                    <div
                        className={(lineClassName && !isMobile )?classes[`rb-lineStepper-progress-indicator`] :classes[`rb-mobileStepper-progress-indicator`]}
                        style={{
                            width: `${mobileProgress}%`,
                            maxWidth: '100%'
                        }}
                    />
                </div>
            </div>}
            <div className={!lineClassName && classes[`rb-topBar-space`]} />
        </>
    )
}

TopBar.defaultProps = {
    showLogo: false,
    showScreenNameInMobile: false,
    showStepper: false
}

export default TopBar